<template>
  <div class="litigationDocumentsDeatil">
    <van-nav-bar
      title="诉讼文书"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <img :src="url" alt="" srcset="" class="urlImg" />
  </div>
</template>

<script>
export default {
  name: "LitigationDocumentsDeatil",
  data() {
    return {
      url: "",
      is_navbar: "true",
    };
  },
  mounted() {
    this.url = this.$route.params.url;
    this.is_navbar = this.$route.params.is_navbar;
    console.log("this.is_navbar", this.is_navbar);
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.urlImg {
  width: 100%;
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
